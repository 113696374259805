import toast from "react-hot-toast";

export const calculateFinalPrice = (item) => {
  if (!item) return 0;

  // Assuming items?.price is a number
  const offerAmount = (item?.price * item?.offer) / 100;
  const finalPrice = item?.price - offerAmount;
  return finalPrice.toFixed(2);
};

export const getToken = () => {
  const userString = localStorage.getItem("user");
  return userString ? JSON.parse(userString) : null;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token?.split(".")[1]));
  } catch (e) {
    return null;
  }
};

// Common error handling function
export const handleErrors = (err, decryptData) => {
  try {
    const errorMessages = decryptData(err?.response?.data?.data);
    if (errorMessages?.status === false && err?.response?.status === 401) {
      // Call isUserLoggedOut function or perform logout logic here
      localStorage.removeItem("user");
      alert("Logged out");
    } else {
      if (errorMessages?.message) {
        toast.error(errorMessages?.message);
      } else {
        toast.error("An error occurred, Please retry again later");
      }
    }
  } catch (error) {
    // Handle any additional errors during error handling
    console.error("Error handling the error:", error);
  }
};

export function shortname(file, size1, size2) {
  const filename = file;
  const shortname =
    filename?.length > 14
      ? filename?.substr(0, size1) + "..." + filename?.substr(size2) //7,-7
      : filename;
  return <>{shortname}</>;
}

export const subtotal = (Cart) =>
  Cart?.products?.reduce(
    (acc, item) => acc + item?.productId?.price * item?.quantity,
    0
  );

export const savings = (Cart) =>
  Cart?.products?.reduce(
    (acc, item) =>
      acc +
      (item?.productId?.price * item?.quantity -
        calculateFinalPrice(item?.productId) * item?.quantity),
    0
  );

export const formattedNumber = (number) => {
  return number?.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const PriceDetails = ({
  totalDiscount,
  mainPrice,
  discountPrice,
  quantity,
}) => (
  <h1 className="flex flex-wrap  gap-2 items-center md:mt-3 mt-2 leading-[10px]">
    <span className="text-[22px] font-bold text-primary ">
      ₹{formattedNumber(totalDiscount * quantity)}
    </span>
    <span className="text-xs font-semibold text-secondary line-through ">
      ₹{formattedNumber(mainPrice * quantity)}
    </span>
    <span className="text-[#CC2E36] text-sm">
      SAVE ₹{formattedNumber(discountPrice * quantity)}
    </span>
  </h1>
);
