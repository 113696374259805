//SLIDER
export const GET_ALL_IMAGE_SLIDER = "GET_ALL_IMAGE_SLIDER";

// CATEGORY
export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";

// PRODUCT
export const GET_ALL_PRODUCT = "GET_ALL_PRODUCT";

// ONE_PRODUCT
export const GET_ONE_PRODUCT = "GET_ONE_PRODUCT";

//SIZE
export const GET_ALL_SIZE = "GET_ALL_SIZE";

//diamondGuide
export const GET_ALL_DIAMOND_GUIDE = "GET_ALL_DIAMOND_GUIDE";

//MATERIAL
export const GET_ALL_MATERIAL = "GET_ALL_MATERIAL";

//PURITY
export const GET_ALL_PURITY = "GET_ALL_PURITY";

//COLLECTION
export const GET_ALL_COLLECTION = "GET_ALL_COLLECTION";

//SignUp
export const SIGN_UP = "SIGN_UP";

//OTP
export const OTP_VERIFICATION = "OTP_VERIFICATION";
export const RESEND_OTP = "RESEND_OTP";

//login
export const SIGN_IN = "SIGN_IN";

//Forget Password
export const FORGET_PASSWORD = "FORGET_PASSWORD";

//ResetPassword
export const RESET_PASSWORD = "RESET_PASSWORD";

//GET_USER_PROFILE
export const GET_USER_PROFILE = "GET_USER_PROFILE";

// ADD TO CART
export const GET_ALL_CART_ITEM = "GET_ALL_CART_ITEM";
export const ADD_TO_CART = "ADD_TO_CART";
export const GET_PRODUCT_COUNT = "GET_PRODUCT_COUNT";

// COUNTRIES
export const GET_ALL_COUNTRIES = "GET_ALL_COUNTRIES";

// COUNTRIES_STATES
export const GET_ALL_COUNTRIES_STATES = "GET_ALL_COUNTRIES_STATES";

// ORDER
export const CREATE_ORDER = "CREATE_ORDER";

// ORDER_LIST
export const CREATE_ORDER_LIST = "CREATE_ORDER_LIST";
