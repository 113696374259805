import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className="h-screen flex items-center p-5  overflow-hidden bg-primary z-50">
      <div className="flex-1 rounded-3xl relative md:flex items-center text-center md:text-left">
        <main className=" w-full flex flex-col justify-center items-center ">
          <div className="mb-5 text-3xl font-bold text-secondary">
            jeslin Jewels
          </div>
          <h1 className="text-9xl font-extrabold text-white tracking-widest">
            404
          </h1>
          <div className="bg-[#257D86] text-white px-2 text-sm rounded rotate-12 absolute">
            Page Not Found
          </div>
          <button className="mt-5">
            <Link
              to="/"
              className="relative inline-block text-sm font-medium text-[#257D86] group  focus:outline-none focus:ring"
            >
              <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#257D86] group-hover:translate-y-0 group-hover:translate-x-0" />
              <span className="relative block px-8 py-3 bg-secondary border border-current">
                Go Home
              </span>
            </Link>
          </button>
        </main>
      </div>
    </div>
  );
}

export default PageNotFound;
