import axios from "axios";
import config from "../Config/Config";
import { getToken } from "../Utils/utils";

const instance = axios.create({
  baseURL: config.base.url,
});

instance.interceptors.request.use(
  (config) => {
    const userToken = getToken();
    const headers = { ...config.headers };

    if (userToken?.Token) {
      headers.Authorization = `Bearer ${userToken.Token}`;
    }

    headers.Accept = headers["Content-Type"] = "application/json";
    config.headers = headers;
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
