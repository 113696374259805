import React from "react";
import {
  Instagram,
  Whatsapp,
  email,
  fb,
  footerImg,
  location,
  // Vaishali Fashion,
  // Vaishali Fashion_Colorchange,
  start,
  telephone,
  x,
} from "../asset/FooterImg";
import { Link } from "react-router-dom";
import { Start } from "../asset/Index";
function Footer() {
  const LinkGroup = ({ children, header }) => {
    return (
      <div>
        <div className="mb-3 gap-0.5  flex items-center ">
          <img src={start} alt="" />
          <p className="text-base font-semibold font-KronaOne text-primary">
            {header}
          </p>
        </div>
        <ul className="font-Poppins space-y-2  text-primary font-medium text-base">
          {children}
        </ul>
      </div>
    );
  };

  const NavLink = ({ link, label }) => {
    return (
      <li className="flex items-center gap-2">
        <span className="flex items-center">
          <span className="h-2 w-2 rounded-full bg-primary"></span>
        </span>
        <Link to={link} target="_blank">
          {label}
        </Link>
      </li>
    );
  };

  const socialMediaLinks = [
    {
      platform: fb,
      link: "",
    },
    {
      platform: Instagram,
      link: "",
    },
    {
      platform: x,
      link: "",
    },
  ];

  return (
    <section className="bg-[#D1D5D4] font-Poppins">
      <footer className="flex items-start gap-3">
        <img src={footerImg} alt="" className="xl:block hidden" />
        <div className="w-full">
          <div className="relative z-10 pt-8 pb-5 px-5 ">
            <div className="container mx-auto ">
              <div className="flex lg:flex-row flex-col justify-between ">
                <div className="lg:w-[4/12] mb-5">
                  <div className="flex flex-col items-start w-52">
                    {/* <a href="/#" className="mb-6">
                      <img src={Vaishali Fashion_Colorchange} alt="" width={200} />
                    </a> */}
                    <p className="font-Poppins text-primary font-bold text-lg">
                      Vaishali Fashion
                    </p>
                    <p className="items-center text-primary">
                      <span>GSTIN :</span> 24DXEPP3735H1ZE
                    </p>
                  </div>
                  <div className="lg:mt-10 mt-5 text-primary ">
                    <div className="mb-2 gap-0.5  flex items-center ">
                      <img src={start} alt="" />
                      <h1 className="text-base font-KronaOne">Contact us</h1>
                    </div>
                    <Link to="/contact-us">Contact us</Link>
                  </div>
                  {/* <div className="">
                    <p className="text-base font-semibold font-Poppins text-primary">
                      Follow us
                    </p>
                    <div className="flex gap-3">
                      {socialMediaLinks.map((icon, index) => (
                        <a
                          href={icon.link}
                          target="_blank"
                          rel="noreferrer"
                          key={index}
                        >
                          <img
                            key={index}
                            src={icon.platform}
                            alt={index}
                            className="w-6"
                          />
                        </a>
                      ))}
                    </div>
                  </div> */}
                </div>
                <div className="lg:block hidden">
                  <div className="flex items-center flex-col flex-1">
                    <p className="h-3 w-3 rounded-full bg-primary"></p>
                    <div className="inline-block h-[100px] min-h-[1em] w-0.5  bg-secondary"></div>
                    <div className="rotate-90">
                      <span className="shrink-0 px-6 ">
                        <img src={Start} alt="" width={100} />
                      </span>
                    </div>
                    <span className="inline-block h-[100px] min-h-[1em] w-0.5  bg-secondary"></span>
                    <p className="h-3 w-3 rounded-full bg-primary"></p>
                  </div>
                </div>
                <div className="flex md:flex-nowrap flex-wrap justify-  w-full gap-5">
                  <div className="lg:w-4/12 ">
                    <div className="">
                      <LinkGroup header="Useful Link">
                        <NavLink
                          link="/shipping-policy"
                          label="Shipping Policy"
                        />
                        <NavLink
                          link="/refund-and-exchange-policy"
                          label="Refund and Exchange Policy"
                        />
                        <NavLink
                          link="/terms-and-conditions"
                          label="Terms And Conditions"
                        />
                        <NavLink link="/privacy-policy" label="PrivacyPolicy" />
                      </LinkGroup>
                    </div>
                    {/* <div className="lg:mt-10 mt-5 text-primary ">
                      <div className="mb-2 gap-0.5  flex items-center ">
                        <img src={start} alt="" />
                        <h1 className="text-base font-KronaOne">
                          Contact us
                        </h1>
                      </div>
                      <Link to="/contact-us">Contact us</Link>
                    </div> */}
                  </div>
                  <div className="text-[14px]">
                    <p className="flex items-start gap-3 text-primary">
                      <img src={location} alt="" width={20} />
                      <span>
                        1ST FLOOR, PLOT NO. 323-324, SONAL IND.ESTATE-1,
                        G.H.B.ROAD, UDHANA, NR. CHIKUWADI, UDHANA, SURAT, Surat,
                        Gujarat, 394210
                      </span>
                    </p>

                    <p className="flex items-center gap-3 my-4 text-primary">
                      <img src={email} alt="" width={20} />
                      <a href="mailto:jeslinjewels@gmail.com">
                        jeslinjewels@gmail.com
                      </a>
                    </p>
                    <p className="flex items-center gap-3 text-primary">
                      <img src={telephone} alt="" width={20} />
                      <a href="tel:+918238728036">+91 82387 28036</a>
                    </p>
                    <p className="flex items-center gap-3 mt-4 text-primary">
                      <img src={Whatsapp} alt="" width={20} />
                      <a href="tel:+918238728036">+91 82387 28036</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap mt-10 gap-5 items-center justify-center">
                <div className="font-semibold">
                  <p className="text-[#1e3b41] text-sm font-Poppins">
                    Copyright © 2024 Vaishali Fashion All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default Footer;
