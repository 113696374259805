import React, { useEffect, useState } from "react";
import useEncryption from "../EncryptData/EncryptData";
import { useDispatch, useSelector } from "react-redux";
import { getCategory, getProductCount } from "../Redux/Action";
import { Link } from "react-router-dom";
import Loader from "../Loader";
import SpeechToText from "./SpeechToText";
import { IoMdCloseCircle } from "react-icons/io";
import { CiMenuFries } from "react-icons/ci";
import { Dhyana_logo } from "../asset/HeaderImg/Index";
import Title from "../Common/Title";
import MyAccount from "./MyAccount";
import { getToken } from "../Utils/utils";
import { MdOutlineDiscount } from "react-icons/md";
import { RiAccountCircleLine } from "react-icons/ri";
import { CiShoppingCart } from "react-icons/ci";
import { MdPhone } from "react-icons/md";

function Header() {
  const { decryptData } = useEncryption();
  let dispatch = useDispatch();
  const { Category, ProductCount } = useSelector((state) => state.data);
  const [isCategoryMenuOpen, setIsCategoryMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const menuItems = [
    // {
    //   icon: <MdOutlineDiscount />,
    //   label: "Discount",
    //   to: "/discount",
    //   showLink: true,
    //   cehckAuth: false,
    // },
    {
      icon: <RiAccountCircleLine />,
      label: "My Account",
      to: "/",
      showLink: false,
      cehckAuth: false,
    },
    {
      icon: <CiShoppingCart />,
      label: "My Cart",
      to: "/cart",
      showLink: true,
      cehckAuth: true,
    },
    {
      icon: <MdPhone />,
      label: "Contact us",
      to: "/contact-us",
      showLink: true,
      cehckAuth: false,
    },
  ];
  const menuItemsMobile = [
    {
      icon: <CiShoppingCart />,
      label: "My Cart",
      to: "/cart",
      showLink: true,
      cehckAuth: true,
    },
    // { icon: Contact, label: "Contact us", to: "/contact-us" },
    {
      icon: <RiAccountCircleLine />,
      label: "My Account",
      to: "/myaccount/profile",
      showLink: false,
      cehckAuth: false,
    },
  ];
  const userToken = getToken();

  const handleCategoryMenuToggle = () => {
    setIsCategoryMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await dispatch(getCategory(decryptData));
        if (userToken?.Token) {
          await dispatch(getProductCount(decryptData));
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, userToken?.Token]);

  const [showPopup, setShowPopup] = useState(false);

  const handleMouseEnter = (itemLabel) => {
    if (itemLabel === "My Account") {
      setShowPopup(true);
    }
  };

  const handleMouseLeave = () => {
    setShowPopup(false);
  };

  return (
    <div className="sticky top-0 z-50  shadow-md">
      <section className="font-KronaOne">
        <header className="bg-white">
          <div className="lg:block hidden bg-white  container mx-auto p-5 ">
            <div className="flex justify-between items-center gap-5">
              <div className="flex-1 flex items-center gap-5">
                <Link to="/">
                  <img src={Dhyana_logo} alt="" width={150} height={200} />
                </Link>
                <div className="lg:w-full">
                  <SpeechToText />
                </div>
              </div>
              <ul className="flex items-center justify-center gap-7 text-thirdColor 2xl:text-[11.5px] text-[10px]">
                {menuItems.map((item, index) => (
                  <li key={index} className="relative text-primary">
                    {item?.showLink ? (
                      <Link
                        to={
                          item?.cehckAuth && !userToken?.Token
                            ? "/login"
                            : item.to
                        }
                        className="flex flex-col items-center gap-2"
                        onMouseEnter={() => handleMouseEnter(item.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span className="text-2xl"> {item.icon}</span>
                        <span>{item.label}</span>
                      </Link>
                    ) : (
                      <div
                        className="flex cursor-pointer flex-col items-center gap-2"
                        onMouseEnter={() => handleMouseEnter(item.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span className="text-2xl"> {item.icon}</span>
                        <span>{item.label}</span>
                      </div>
                    )}
                    {item.label === "My Cart" && (
                      <div className="relative">
                        <div className="-top-16 absolute left-10">
                          <p className="flex h-2 w-2 items-center justify-center rounded-full bg-red-500 p-3 text-xs text-white">
                            {ProductCount || 0}
                          </p>
                        </div>
                      </div>
                    )}

                    {item.label === "My Account" && showPopup && (
                      <div
                        className="absolute top-full left-1/2 w-72 transform -translate-x-1/2 z-[60] "
                        onMouseEnter={() => handleMouseEnter(item.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <MyAccount />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="bg-white border-t 2xl:text-[12px] text-[10.5px] pt-5 px-2 w-full mt-5">
              <ul className="flex gap-5 justify-start text-primary truncate">
                {Category?.map((item, index) => (
                  <li key={index}>
                    <Link to={`/jewellery?category=${item.slug}`}>
                      {item?.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </header>
        <nav className="lg:hidden block bg-white sticky top-0 p-5">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <CiMenuFries
                onClick={handleCategoryMenuToggle}
                className="text-4xl text-primary"
              />
              <Link to="/">
                <img src={Dhyana_logo} alt="" width={150} />
              </Link>
            </div>

            <div className="w-full ">
              <ul className="flex items-center justify-end gap-3 text-primary text-[9px] w-full">
                {menuItemsMobile.map((item, index) => (
                  <li key={index} className="">
                    {item?.showLink ? (
                      <Link
                        to={item.to}
                        className="flex flex-col items-center gap-2"
                        onMouseEnter={() => handleMouseEnter(item.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span className="text-2xl">{item.icon}</span>
                        <span>{item.label}</span>
                      </Link>
                    ) : (
                      <div
                        className="flex cursor-pointer flex-col items-center gap-2"
                        onMouseEnter={() => handleMouseEnter(item.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span className="text-2xl">{item.icon}</span>
                        <span>{item.label}</span>
                      </div>
                    )}
                    {item.label === "My Cart" && (
                      <div className="relative">
                        <div className="-top-14 absolute left-9">
                          <p className="flex h-2 w-2 items-center justify-center rounded-full bg-red-500 p-3 text-xs text-white">
                            {ProductCount || 0}
                          </p>
                        </div>
                      </div>
                    )}

                    {item.label === "My Account" && showPopup && (
                      <div
                        className="absolute  top-full w-full left-1/2 transform -translate-x-1/2 z-[60] "
                        onMouseEnter={() => handleMouseEnter(item.label)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <MyAccount />
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mt-5">
            <SpeechToText />
          </div>
        </nav>
        {isCategoryMenuOpen && (
          <div className="lg:hidden block fixed top-0 left-0 w-60 bg-primary overflow-y-scroll px-2 py-5  text-secondary  h-screen z-50">
            <div className="flex justify-end mr-5">
              <IoMdCloseCircle onClick={handleCategoryMenuToggle} />
            </div>
            <ul>
              {Category?.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/jewellery?category=${item.slug}`}
                    className="flex items-center gap-5 mt-3"
                    onClick={handleCategoryMenuToggle}
                  >
                    <div className="overflow-hidden rounded-md">
                      <img
                        alt="Signage"
                        src={item.image}
                        className="w-16 object-cover h-16 mx-auto hover:scale-105 transition duration-500"
                      />
                    </div>
                    <Title
                      className="text-secondary text-center  text-sm truncate "
                      title={item.name}
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>
      {isLoading && <Loader />}
    </div>
  );
}

export default Header;
