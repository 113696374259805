import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { micro_phone, search } from "../asset/HeaderImg/Index";

const SpeechToText = () => {
  const [manualInput, setManualInput] = useState("");
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleInputChange = (e) => {
    setManualInput(e.target.value);
    resetTranscript();
  };

  const handleSpeechClick = () => {
    resetTranscript(); // Reset speech transcript
    SpeechRecognition.startListening();
    setManualInput(""); // Clear manual input when speech-to-text is activated
  };

  const handleSearchClick = () => {
    // Extract the search query from manualInput or transcript
    const searchQuery = manualInput || transcript;

    // Check if the search query is not empty before navigating
    if (searchQuery) {
      // Navigate to the search URL
      navigate(
        `/jewellery?search=${encodeURIComponent(searchQuery)}`
      );
    }
  };

  return (
    <div className="flex items-center gap-2 px-3 justify-between bg-white rounded-lg border border-primary">
      <img
        src={search}
        alt=""
        width={18}
        className="cursor-pointer"
        onClick={handleSearchClick}
      />
      <input
        type="text"
        className="h-9 w-full focus:outline-none hover:cursor-pointer text-[10px]"
        name=""
        placeholder="Search entire store here"
        value={manualInput || transcript}
        onChange={handleInputChange}
      />
      <img
        src={micro_phone}
        alt=""
        width={18}
        onClick={handleSpeechClick}
        className="cursor-pointer"
      />
    </div>
  );
};

export default SpeechToText;
