import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import RoutesPaths from "./routes/index";
import Error from "../components/404/PageNotFound";
import { useEffect } from "react";
import { getToken } from "../components/Utils/utils";

const ScrollToTop = ({ children }) => {
  const { pathname, hash, key, search } = useLocation();
  useEffect(() => {
    // Scroll to top when the path changes
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [pathname, search]);
  useEffect(() => {
    if (hash) {
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash, key]);

  return children;
};

const Router = () => {
  const location = useLocation();
  const userToken = getToken();
  const FinalRoute = (props) => {
    const route = props?.route;
    if (userToken?.Token === undefined && route?.meta?.authRoute) {
      return (
        <Navigate to="/" replace={true} state={{ path: location?.pathname }} />
      );
    } else {
      return <route.component {...props} />;
    }
  };

  return (
    <ScrollToTop>
      <Routes>
        {RoutesPaths.map((route, index) => {
          if (route.children) {
            return (
              <Route
                key={index}
                path={route?.path}
                element={<FinalRoute route={route} />}
              >
                {route.children.map((childRoute, childIndex) => (
                  <Route
                    key={childIndex}
                    path={childRoute.path}
                    element={<FinalRoute route={childRoute} />}
                  />
                ))}
              </Route>
            );
          } else {
            return (
              <Route
                exact
                key={index}
                path={route?.path}
                element={<FinalRoute route={route} />}
              />
            );
          }
        })}
        <Route exact path="*" element={<Error />} />
      </Routes>
    </ScrollToTop>
  );
};

export default Router;
