import React from "react";
import { socialMedia } from "../Data/Mapping";
function SocialMedia() {
  return (
    <div className="lg:block hidden bg-primary text-secondary py-[8px]  px-5 font-KronaOne">
      <div className="flex justify-between gap-[73px] container mx-auto">
        <ul className="flex gap-[25px] text-[11px]">
          {socialMedia.map((item, index) => (
            <li key={index}>
              <a
                href={item.to}
                target="_blank"
                rel="noreferrer"
                className="flex items-center gap-2"
              >
                <span className="text-base">{item.icon}</span>
                <span>{item.text}</span>
              </a>
            </li>
          ))}
        </ul>

        <div className="flex  items-center gap-1.5">
          <p className="text-sm font-Poppins">
            we are selling best imitation jewellery!
          </p>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
