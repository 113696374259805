import { closeEye, openeye } from "../asset/Index";
import { MdEmail, MdPhone } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaFacebook } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
const socialMediaLinks = [
  {
    platform: <FaFacebook />,
    link: "",
  },
  {
    platform: <AiFillInstagram />,
    link: "",
  },
  {
    platform: <FaXTwitter />,
    link: "",
  },
];
const socialMedia = [
  {
    icon: <MdEmail />,
    text: "jeslinjewels@gmail.com",
    to: "mailto:jeslinjewels@gmail.com",
  },
  {
    icon: <MdPhone />,
    text: "+91 82387 28036",
    to: "tel:+918238728036",
  },
  {
    icon: <IoLogoWhatsapp />,
    text: "+91 82387 28036",
    to: `https://wa.me/+918238728036`,
  },
];

const userDataSignUp = ({
  error,
  username,
  onChangeUsername,
  completeEmail,
  onChangeEmail,
  showPass,
  password,
  onChangePassword,
  onShowPassword,
  showConfirmPass,
  cpwd,
  onChangeCpassword,
  onshowConfirmPass,
  Referral,
  onChangeReferralCode,
}) => {
  return [
    {
      id: 0,
      errorusername: error.username,
      type: "text",
      placeholder: "User Name",
      value: username,
      onChange: onChangeUsername,
    },
    {
      id: 1,
      errorusername: error.email,
      type: "text",
      placeholder: "User Email",
      value: completeEmail,
      onChange: onChangeEmail,
    },
    {
      id: 2,
      errorusername: error.password,
      type: showPass ? "text" : "password",
      placeholder: "User Password",
      value: password,
      onChange: onChangePassword,
      passwordimg: showPass ? openeye : closeEye,
      onShowPassword: onShowPassword,
    },
    {
      id: 3,
      errorusername: error.cpwd,
      type: showConfirmPass ? "text" : "password",
      placeholder: "Confirm Password",
      value: cpwd,
      onChange: onChangeCpassword,
      passwordimg: showConfirmPass ? openeye : closeEye,
      onShowPassword: onshowConfirmPass,
    },
    {
      id: 5,
      type: "text",
      placeholder: "Coupon code (Optional)",
      value: Referral,
      onChange: onChangeReferralCode,
    },
  ];
};
export { socialMediaLinks, socialMedia, userDataSignUp };
