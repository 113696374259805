import { lazy } from "react";

const RoutesPaths = [
  {
    path: "/",
    component: lazy(() => import("../../components/Home/Home")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/jewellery",
    component: lazy(() =>
      import(
        "../../components/Product/ProductListByCategory/ProductListByCategory"
      )
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/product-jewellery",
    component: lazy(() =>
      import("../../components/Product/ProductDetalis/ProductDetalis")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/contact-us",
    component: lazy(() => import("../../components/ContactUs/ContactUs")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/sign-up",
    component: lazy(() => import("../../components/Auth/SingUp/SingUp")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/otp",
    component: lazy(() => import("../../components/Auth/OTP/Otp")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/login",
    component: lazy(() => import("../../components/Auth/Login/Login")),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/forgotPassword",
    component: lazy(() =>
      import("../../components/Auth/ForgotPassword/ForgotPassword")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/resetPassword",
    component: lazy(() =>
      import("../../components/Auth/ResetPassword/ResetPassword")
    ),
    meta: {
      authRoute: false,
    },
  },

  {
    path: "/myaccount",
    component: lazy(() => import("../../components/Profile/Profile")),
    meta: {
      authRoute: true,
    },
    children: [
      {
        path: "profile",
        component: lazy(() =>
          import("../../components/Profile/MyAccount/ProfileDetalis")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "orders",
        component: lazy(() =>
          import("../../components/Profile/Orders/OrdersDetalis")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "changePassword",
        component: lazy(() =>
          import("../../components/Profile/ChangePassword/ChangePassword")
        ),
        meta: {
          authRoute: true,
        },
      },
      {
        path: "shippingAddress",
        component: lazy(() =>
          import("../../components/Profile/ShippingAddress/ShippingAddress")
        ),
        meta: {
          authRoute: true,
        },
      },
    ],
  },
  {
    path: "/cart",
    component: lazy(() => import("../../components/Cart/Cart")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/checkout",
    component: lazy(() => import("../../components/Cart/CheckOut/CheckOut")),
    meta: {
      authRoute: true,
    },
  },
  {
    path: "/shipping-policy",
    component: lazy(() =>
      import("../../components/Policy/ShippingPolicy/ShippingPolicy")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/privacy-policy",
    component: lazy(() =>
      import("../../components/Policy/PrivacyPolicy/PrivacyPolicy")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/terms-and-conditions",
    component: lazy(() =>
      import("../../components/Policy/TermsAndConditions/TermsAndConditions")
    ),
    meta: {
      authRoute: false,
    },
  },
  {
    path: "/refund-and-exchange-policy",
    component: lazy(() =>
      import("../../components/Policy/RefundAndExchangePolicy/RefundAndExchangePolicy")
    ),
    meta: {
      authRoute: false,
    },
  },
];

export default RoutesPaths;
