import * as types from "./ActionType";
import {
  createAction,
  fetchDataWithoutPagination,
  getOneItem,
} from "../Api/endpoint";
import instance from "../BaseUrl/BaseUrl";
import toast from "react-hot-toast";
import { handleErrors } from "../Utils/utils";

// Example usage for "SLIDER"
const getImageSlidersuccess = createAction(
  types.GET_ALL_IMAGE_SLIDER,
  (ImageSliders) => ImageSliders
);

// Example usage for "CATEGORY"
const getCategorySuccess = createAction(
  types.GET_ALL_CATEGORY,
  (Category) => Category
);

// Example usage for "PRODUCT"
const getProductSuccess = createAction(
  types.GET_ALL_PRODUCT,
  (Product) => Product
);

// Example usage for "ONE_PRODUCT"
const getOneProductSuccess = createAction(
  types.GET_ONE_PRODUCT,
  (Product) => Product
);

// Example usage for "SIZE"
const getSizeGuideSuccess = createAction(
  types.GET_ALL_SIZE,
  (SizeGuide) => SizeGuide
);

// Example usage for "DIAMOND_GUIDE"
const getDiamondGuideSuccess = createAction(
  types.GET_ALL_DIAMOND_GUIDE,
  (DiamondGuide) => DiamondGuide
);

// Example usage for "MATERIAL"
const getMaterialSuccess = createAction(
  types.GET_ALL_MATERIAL,
  (Material) => Material
);

// Example usage for "PURITY"
const getPuritySuccess = createAction(types.GET_ALL_PURITY, (Purity) => Purity);

// Example usage for "Collection"
const getAllCollectionSuccess = createAction(
  types.GET_ALL_COLLECTION,
  (Collection) => Collection
);

// SIGNUP
const signUp = () => ({
  type: types.SIGN_UP,
});

// OTP
const userOtp = (users) => ({
  type: types.OTP_VERIFICATION,
  payload: users,
});

// SIGNIN
const signIn = (token) => ({
  type: types.SIGN_IN,
  payload: token,
});

// RESENDOTP
const resendOtp = () => ({
  type: types.RESEND_OTP,
});

// RESENDOTP
const forgetPassword = () => ({
  type: types.FORGET_PASSWORD,
});

// RESETPASSWORD
const resetPassword = () => ({
  type: types.RESET_PASSWORD,
});

// Example usage for "User Profile"
const getUserProfileSuccess = createAction(
  types.GET_USER_PROFILE,
  (UserProfile) => UserProfile
);

// Example usage for "Cart"
const getCartItemSuccess = createAction(
  types.GET_ALL_CART_ITEM,
  (Cart) => Cart
);

// Action creator for  product count
const getProductCountSuccess = createAction(
  types.GET_PRODUCT_COUNT,
  (ProductCount) => ProductCount
);

// Action creator for  Countries count
const getCountriesSuccess = createAction(
  types.GET_ALL_COUNTRIES,
  (Countries) => Countries
);

// Action creator for  CountriesStates count
const getCountriesStatesSuccess = createAction(
  types.GET_ALL_COUNTRIES_STATES,
  (CountriesStates) => CountriesStates
);

const addOrderListSuccess = createAction(
  types.CREATE_ORDER_LIST,
  (OrderList) => OrderList
);
const createOrderSuccess = createAction(types.CREATE_ORDER, (Order) => Order);

// Example usage for "ImageSliders"
/* Get All ImageSliders*/
export const getImageSliders = fetchDataWithoutPagination(
  "admin/homeSlider/getAllSlider",
  getImageSlidersuccess
);

// Example usage for "Category"
/* Get All Category */
export const getCategory = fetchDataWithoutPagination(
  "admin/category/getAllNoPagCategory",
  getCategorySuccess
);

// Example usage for "Product"
/* Get All Product */
export const getProduct = ({
  decryptData,
  categoryName,
  collectionName,
  selectedValues,
  search,
}) => {
  const {
    material = null,
    purity = null,
    gender = null,
  } = selectedValues || {};
  return async (dispatch) => {
    try {
      // Build the base URL
      let url = `/admin/product/getAllProductByCategory`;
      // Add query parameters conditionally
      const queryParams = new URLSearchParams();

      if (categoryName) {
        queryParams.append("categoryName", categoryName);
      }

      if (collectionName) {
        queryParams.append("collectionName", collectionName);
      }

      if (material) {
        queryParams.append("material", material);
      }

      if (purity) {
        queryParams.append("purity", purity);
      }

      if (gender) {
        queryParams.append("gender", gender);
      }

      if (search) {
        queryParams.append("search", search);
      }
      // Append the query parameters to the URL
      if (queryParams.toString()) {
        url += `?${queryParams.toString()}`;
      }

      // Make the request with the dynamically built URL
      const encryptDataResult = await instance.get(url);
      const result = decryptData(encryptDataResult.data.data);
      if (encryptDataResult.status === 200) {
        const { data } = result;
        dispatch(getProductSuccess(data));
      }
    } catch (err) {
      console.log(err);
    }
  };
};

// Example usage for "One Product"
/* Get One Product */
export const getOneProduct = getOneItem(
  "admin/product/getOneProductByCategory",
  getOneProductSuccess
);

// Example usage for "SizeGuide"
/* Get All SizeGuide */
export const getSizeGuide = fetchDataWithoutPagination(
  "admin/productSize/getAllNoPagProductSize",
  getSizeGuideSuccess
);

// Example usage for "DiamondGuide"
/* Get All DiamondGuide */
export const getDiamondGuide = fetchDataWithoutPagination(
  "admin/diamondGuide/getAllNoPagDiamondGuide",
  getDiamondGuideSuccess
);

// Example usage for "Material"
/* Get All Material */
export const getMaterial = fetchDataWithoutPagination(
  "admin/material/getAllNoPagMaterial",
  getMaterialSuccess
);

// Example usage for "Purity"
/* Get All Purity */
export const getPurity = fetchDataWithoutPagination(
  "admin/purity/getAllNoPagPurity",
  getPuritySuccess
);

// Example usage for "Purity"
/* Get All Purity */
export const getCollections = fetchDataWithoutPagination(
  "admin/collection/getAllNoPagCollection",
  getAllCollectionSuccess
);

// SignUp -API
export const SignUpUser = (encryptData, decryptData, formData, navigate) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify(formData));
      const encryptDataResult = await instance.post("/user/sign-up", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status === 201) {
        const { data, message } = result;
        toast.success(message);
        dispatch(signUp());
        localStorage.setItem(
          "user",
          JSON.stringify({
            Token: data.accessToken,
            username: data.userName,
            email: data.email,
          })
        );
        const storedData = localStorage.getItem("cartData");
        const cartData = JSON.parse(storedData);
        if (cartData) {
          dispatch(
            AddToCartProduct(encryptData, decryptData, cartData, navigate)
          );
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Otp -API
export const OtpVerification = (
  encryptData,
  decryptData,
  route,
  otp,
  email,
  password,
  confirmPassword,
  fullName,
  phoneNumber,
  countryCode,
  country,
  navigate
) => {
  return async function (dispatch) {
    try {
      const data =
        route === "/forgotPassword"
          ? { email, otp }
          : {
              email,
              password,
              confirmPassword,
              fullName,
              phoneNumber,
              otp,
              country,
              countryCode,
            };

      const encrypt = encryptData(JSON.stringify(data));
      const encryptDataResult = await instance.post(
        route === "/forgotPassword" ? "/auth/verify-otp" : "/user/sign-up",
        { data: encrypt }
      );
      const result = decryptData(encryptDataResult.data.data);

      if (
        encryptDataResult.status === 200 ||
        encryptDataResult.status === 201
      ) {
        const { message, data } = result;

        toast.success(message);
        dispatch(userOtp(data));
        if (route === "/sign-up") {
          localStorage.setItem(
            "user",
            JSON.stringify({
              Token: data.accessToken,
              username: data.userName,
              email: data.email,
            })
          );
        }
        if (route === "/forgotPassword") {
          const encodedEmail = encodeURIComponent(email);
          const encodedOtp = encodeURIComponent(otp);
          const redirectUrl = `/resetpassword?email=${encodedEmail}&otp=${encodedOtp}`;
          navigate(redirectUrl);
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Resend Otp -API
export const ResendOtp = (
  encryptData,
  decryptData,
  route,
  email,
  setTimerCount,
  defaultCount,
  timer
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(
        JSON.stringify(
          route === "/forgotPassword"
            ? { email }
            : {
                email,
                type: "userverification",
              }
        )
      );
      const encryptDataResult = await instance.post(
        route === "/forgotPassword"
          ? "/auth/forgot-password"
          : "/user/otp/generate",
        {
          data: encrypt,
        }
      );
      const result = decryptData(encryptDataResult.data.data);
      if (
        encryptDataResult.status === 200 ||
        encryptDataResult.status === 201
      ) {
        setTimerCount(defaultCount);
        timer();
        const { message } = result;
        toast.success(message);
        dispatch(resendOtp());
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// SignIn -API
export const SignInUser = (encryptData, decryptData, formData, navigate) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify(formData));
      const encryptDataResult = await instance.post("/auth/login", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult?.status === 200) {
        const { message, data } = result;
        toast.success(message);
        dispatch(signIn(data));
        localStorage.setItem(
          "user",
          JSON.stringify({
            Token: data.accessToken,
            username: data.userName,
            email: data.email,
          })
        );
        const storedData = localStorage.getItem("cartData");
        const cartData = JSON.parse(storedData);
        if (cartData) {
          dispatch(
            AddToCartProduct(encryptData, decryptData, cartData, navigate)
          );
        } else {
          navigate("/");
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

//Forget Password API
export const forgetPassworduser = (
  encryptData,
  decryptData,
  formData,
  setopenotpbox
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify(formData));
      const encryptDataResult = await instance.post("/auth/forgot-password", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status === 200) {
        const { message } = result;
        toast.success(message);
        setopenotpbox(true);
        dispatch(forgetPassword());
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

//ResetPassword API
export const ResetPasswordUser = (
  encryptData,
  decryptData,
  formData,
  navigate
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify(formData));
      const encryptDataResult = await instance.post("/auth/reset-password", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status) {
        const { message } = result;
        toast.success(message);
        navigate("/login");
        dispatch(resetPassword());
      } else {
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Example usage for "User Profile"
/* Get User Profile */
export const getUserProfile = (decryptData) => {
  return async function (dispatch) {
    try {
      const encryptDataResult = await instance.get("/user/profile");
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status === 200) {
        const { data } = result;
        dispatch(getUserProfileSuccess(data));
      } else {
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Example usage for "Update User Profile Adress"
/* Update User Profile Adress*/
export const updateUserProfileAddress = (
  encryptData,
  decryptData,
  shippingAdress,
  closeShippingAddress,
  isFromProfilers,
  handleContinue
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(
        JSON.stringify({ shippingAdress: shippingAdress })
      );
      const encryptDataResult = await instance.put("/user/updateProfile", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status === 200) {
        const { data } = result;
        dispatch(getUserProfileSuccess(data));
        if (!isFromProfilers) {
          closeShippingAddress();
          handleContinue();
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// ChangePassword  -API
export const changePassword = (
  encryptData,
  decryptData,
  password,
  newPassword,
  navigate
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(
        JSON.stringify({
          password,
          newPassword,
        })
      );
      const encryptDataResult = await instance.patch("/user/reset/password", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status === 200) {
        const { message } = result;
        toast.success(message);
        navigate("/login");
        localStorage.removeItem("user");
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Add to Cart -API
export const AddToCartProduct = (encryptData, decryptData, data, navigate) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify(data));
      const encryptDataResult = await instance.post("/cart/add", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if ([201, 200].includes(encryptDataResult?.status)) {
        const { message, status } = result;
        if (status) {
          toast.success(message);
          dispatch(getProductCount(decryptData));
          navigate("/cart");
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Update to Cart -API
export const UpdateToCartProduct = (
  encryptData,
  decryptData,
  productId,
  quantity,
  handlePopup
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify({ productId, quantity }));
      const encryptDataResult = await instance.put("/cart/update", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult?.status === 200) {
        const { message } = result;
        toast.success(message);
        dispatch(getCartItem(decryptData));
        handlePopup();
        dispatch(getProductCount(decryptData));
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Example usage for "Cart Item"
/* Get All Cart Item */
export const getCartItem = fetchDataWithoutPagination(
  "cart/getAllItem",
  getCartItemSuccess
);

// Delete to Cart Item -API
export const DeleteCartProduct = (decryptData, productId) => {
  return async function (dispatch) {
    try {
      const encryptDataResult = await instance.delete(
        `/cart/deleteProduct/${productId}`
      );
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult?.status === 200) {
        const { message } = result;
        toast.success(message);
        dispatch(getCartItem(decryptData));
        dispatch(getProductCount(decryptData));
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

/* Get Product Count */
export const getProductCount = fetchDataWithoutPagination(
  "cart/products/count",
  getProductCountSuccess
);

// Example usage for "Countries"
/* Get All Countries */
export const getCountries = fetchDataWithoutPagination(
  "countriesData/getAllCountries",
  getCountriesSuccess
);

// Example usage for "CountriesStates"
/* Get All CountriesStates */
export const getCountriesStates = fetchDataWithoutPagination(
  "countriesData/getAllCountriesStates",
  getCountriesStatesSuccess
);

// Example usage for "check-before-order"
/* check-before-order */
export const checkBeforeOrder = (
  encryptData,
  decryptData,
  setOutOfStock,
  setOpenOutOfStock,
  navigation
) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify({}));
      const encryptDataResult = await instance.post(
        "/cart/check-before-order",
        {
          data: encrypt,
        }
      );
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult?.status === 200) {
        const { data, status, message } = result;
        if (status) {
          navigation("/checkout");
        } else {
          toast.error(message);
          setOpenOutOfStock(true);
          setOutOfStock(data);
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

export const createOrder = (encryptData, decryptData) => {
  return async function (dispatch) {
    try {
      const encrypt = encryptData(JSON.stringify({}));
      const encryptDataResult = await instance.post("/payment/orders", {
        data: encrypt,
      });
      const result = decryptData(encryptDataResult.data.data);
      if (encryptDataResult?.status === 201) {
        const { data, status, message } = result;
        if (status) {
          dispatch(createOrderSuccess(data));
        }
      }
    } catch (err) {
      handleErrors(err, decryptData);
    }
  };
};

// Example usage for "OrderList"
/* Get All OrderList */
export const getOrderList = fetchDataWithoutPagination(
  "user/getAllOrder",
  addOrderListSuccess
);
