import cryptoJS from "crypto-js";

const useEncryption = () => {
  const cryptoKey = process.env.REACT_APP_ENCRYPT_KEY;
  if (!cryptoKey) {
    throw new Error(
      "Encryption key is missing or invalid. Please check your configuration."
    );
  }

  const encryptData = (data) => {
    try {
      let dataToBeEncrypted;

      if (typeof data === "string") dataToBeEncrypted = data;
      else if (typeof data === "number") dataToBeEncrypted = data.toString();

      return cryptoJS.AES.encrypt(dataToBeEncrypted, cryptoKey).toString();
    } catch (err) {
      console.log(err);
      throw new Error("Error encrypting data.");
    }
  };

  const decryptData = (encryptedData) => {
    try {
      const plain = cryptoJS.AES.decrypt(encryptedData.toString(), cryptoKey);

      return JSON.parse(plain.toString(cryptoJS.enc.Utf8));
    } catch (err) {
      console.log(err);
      throw new Error(
        "Error decrypting data. Possibly due to an incorrect key."
      );
    }
  };

  return {
    encryptData,
    decryptData,
  };
};

export default useEncryption;
