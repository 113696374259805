import instance from "../BaseUrl/BaseUrl";

const createAction = (type, payloadFunc) => (args) => ({
  type,
  payload: payloadFunc(args),
});

const fetchDataWithoutPagination =
  (endpoint, successAction) =>
  (decryptData, categorySlug) =>
  async (dispatch) => {
    try {
      let url = `/${endpoint}`;
      if (categorySlug) {
        url += `/${categorySlug}`;
      }

      const encryptDataResult = await instance.get(url);
      const result = decryptData(encryptDataResult.data.data);
      if (encryptDataResult.status === 200) {
        const { data } = result;
        dispatch(successAction(data));
      }
    } catch (err) {
      console.log(err);
    }
  };

// Reusable function to fetch data for an endpoint Get Single Data
const getOneItem =
  (endpoint, successAction) => (decryptData, slug) => async (dispatch) => {
    try {
      const encryptDataResult = await instance.get(`/${endpoint}/${slug}`);
      const result = decryptData(encryptDataResult.data.data);

      if (encryptDataResult.status === 200) {
        const { data } = result;
        dispatch(successAction(data));
      }
    } catch (err) {
      console.log(err);
    }
  };

export { fetchDataWithoutPagination, createAction, getOneItem };
