import { Suspense, useEffect } from "react";
import Router from "./Routers/router";
import toast, { Toaster } from "react-hot-toast";
import Header from "./components/LayOut/Header";
import Footer from "./components/LayOut/Footer";
import Download from "./components/LayOut/DownloadApp";
import WhatsAppButton from "./components/Whatsapp/WhatsAppButton";
import Loader from "./components/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken, parseJwt } from "./components/Utils/utils";
import { EnglishConstant } from "./components/languages/englishMessages";
import SocialMedia from "./components/LayOut/SocialMedia";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const userToken = getToken();
  var decodedJwt = parseJwt(userToken?.Token);
  const shouldRenderNavbar = [
    "/login",
    "/sign-up",
    "/forgotPassword",
    "/resetPassword",
    "/otp",
  ].includes(pathname);

  useEffect(() => {
    if (userToken?.Token && shouldRenderNavbar) {
      navigate("/");
    }
  }, [userToken, navigate, pathname, shouldRenderNavbar]);

  const expirationTime = decodedJwt?.exp * 1000;
  const expirationDate = new Date(expirationTime);
  const formattedExpiration = expirationDate.toLocaleString();

  useEffect(() => {
    if (userToken?.Token && formattedExpiration < Date.now()) {
      localStorage.removeItem("user");
      navigate("/login");
      toast.error(EnglishConstant.loggedout);
    }
  }, [userToken, decodedJwt?.exp, navigate, formattedExpiration]);

  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 2500,
        }}
        containerStyle={{
          zIndex: 99999999, // For the container
        }}
      />
      <SocialMedia />
      <Header />
      <Suspense fallback={<Loader />}>
        <Router />
      </Suspense>
      <WhatsAppButton />
      <Footer />
    </div>
  );
}

export default App;
