import React from "react";
import { Download_App, download_section } from "../asset/Index";

function Download() {
  return (
    <div className="bg-primary font-KronaOne flex items-center justify-end relative">
      <div>
        <img src={Download_App} alt="" className="opacity-40 ml-auto" />
      </div>
      <div className="absolute w-full ">
        <section className=" container mx-auto flex items-center justify-center gap-16 px-5">
          <div>
            <img src={download_section} alt="" width={600} />
          </div>
        </section>
      </div>
    </div>
  );
}

export default Download;
