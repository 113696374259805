import * as types from "./ActionType";

const initialState = {
  Token: {},
  ImageSliders: [],
  Category: [],
  Product: [],
  OneProduct: null,
  SizeGuide: [],
  DiamondGuide: [],
  Material: [],
  Purity: [],
  Collection: [],
  Countries: [],
  CountriesStates: [],
  UserProfile: null,
  Cart: null,
  ProductCount: null,
  Order: null,
  OrderList: [],
};

const userReducers = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Add handling for Category
    case types.GET_ALL_CATEGORY:
      return { ...state, Category: payload, loading: false };

    // Add handling for Slider
    case types.GET_ALL_IMAGE_SLIDER:
      return { ...state, ImageSliders: payload, loading: false };

    // Add handling for Product
    case types.GET_ALL_PRODUCT:
      return { ...state, Product: payload, loading: false };

    // Add handling for OneProduct
    case types.GET_ONE_PRODUCT:
      return { ...state, OneProduct: payload, loading: false };

    // Add handling for SizeGuide
    case types.GET_ALL_SIZE:
      return { ...state, SizeGuide: payload, loading: false };

    // Add cases for diamondGuide actions
    case types.GET_ALL_DIAMOND_GUIDE:
      return { ...state, DiamondGuide: payload, loading: false };

    // Add cases for Material actions
    case types.GET_ALL_MATERIAL:
      return { ...state, Material: payload, loading: false };

    // Add cases for Purity actions
    case types.GET_ALL_PURITY:
      return { ...state, Purity: payload, loading: false };

    // Add cases for Collection actions
    case types.GET_ALL_COLLECTION:
      return { ...state, Collection: payload, loading: false };

    // Add handling for User Profile
    case types.GET_USER_PROFILE:
      return { ...state, UserProfile: payload, loading: false };

    case types.OTP_VERIFICATION:
    case types.SIGN_IN:
      return { ...state, Token: action.payload, loading: false };
    case types.SIGN_UP:
    case types.RESEND_OTP:
    case types.FORGET_PASSWORD:
    case types.RESET_PASSWORD:
      return {
        ...state,
        loading: false,
      };

    // Add cases for Cart actions
    case types.GET_ALL_CART_ITEM:
      return { ...state, Cart: payload, loading: false };
    case types.ADD_TO_CART:
      return { ...state, Cart: [...state.Cart, ...payload] };
    case types.GET_PRODUCT_COUNT:
      return { ...state, ProductCount: payload, loading: false };

    // Add cases for Countries actions
    case types.GET_ALL_COUNTRIES:
      return { ...state, Countries: payload, loading: false };
    case types.GET_ALL_COUNTRIES_STATES:
      return { ...state, CountriesStates: payload, loading: false };

    case types.CREATE_ORDER:
      return { ...state, Order: payload, loading: false };

    case types.CREATE_ORDER_LIST:
      return { ...state, OrderList: payload, loading: false };
    default:
      return state;
  }
};

export default userReducers;
