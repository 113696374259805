import React from "react";
import { Link } from "react-router-dom";
import { getToken } from "../Utils/utils";

function MyAccount() {
  const userToken = getToken();
  const handleLogout = () => {
    localStorage.removeItem("user");
    window.location.reload();
    alert("Logged out");
  };

  return (
    <div>
      <div className="rounded-lg bg-white p-8 shadow-2xl font-Poppins">
        {userToken?.Token ? (
          <div className="">
            <h2 className="text-primary font-semibold text-xl">
              {userToken?.username}
            </h2>

            <p className="mt-2 text-secondary text-sm">{userToken?.email}</p>

            <div className="mt-4 flex gap-2">
              <Link
                to="/myaccount/profile"
                className="rounded bg-primary px-4 py-2 text-sm font-medium text-thirdColor uppercase"
              >
                My Account
              </Link>

              <button
                onClick={handleLogout}
                className="rounded border border-primary px-4 py-2 text-sm font-medium text-gray-600 uppercase"
              >
                Logout
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-primary font-semibold text-xl">Your Account</h2>

            <p className="mt-2 text-secondary text-sm">
              Access account & manage your orders.
            </p>

            <div className="mt-4 flex gap-2">
              <Link
                to="/sign-up"
                className="rounded bg-primary px-4 py-2 text-sm font-medium text-thirdColor"
              >
                Sign Up
              </Link>

              <Link
                to="/login"
                className="rounded border border-primary px-4 py-2 text-sm font-medium text-gray-600"
              >
                Log In
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyAccount;
