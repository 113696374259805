export const EnglishConstant = {
  /* Signup and Login */
  Name: "Name  is required!",
  Phone: "Phone Number  is required!",
  Message: "message  is required!",
  loggedout: "you've logged out please log back in.",

  /* Signup and Login */
  Login: "You have Login successfully.",
  Signup: "You have Signup successfully.",

  /* OTP */
  OTP: "*OTP is required!",
  NewOtp: "New Otp is send",

  /* User */
  Username: "*Username is required!",
  PhoneNumber: "*Phone-Number is required!",
  PhoneNumberLength: "*Phone-Number must be 10 or more characters!",
  Email: "*Email address is required!",
  Emailinvalid: "*Email address is invalid!",
  privacy:
    "You need to accept our terms and privacy policy to create an account.",

  /* Profile */
  oldpassword: "*Old Password is required!",
  password: "*Password is required!",
  passwordLength: "*Password must be 8 or more characters!",
  ConfirmPassword: "*Confirm Password is required!",
  ConfirmPasswordMatched: "*Confirm password is not matched!",
};
